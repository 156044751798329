<template lang="pug">
  .row
    .col-md-3
      roles-list
    .col-md-9
      v-card
        v-card-head
          role-actions
        v-card-content
          .row
            .col-md-6
              role-owners
            .col-md-6.mobile-top-offset
              role-permissions
</template>

<script>
import RolesList from './roles/RolesList'
import RoleActions from './roles/RoleActions'
import RoleOwners from './owners/RoleOwners'
import RolePermissions from './roles/RolePermissions'

export default {
  name: 'Permissions',

  components: {
    RolesList,
    RoleActions,
    RoleOwners,
    RolePermissions
  }
}
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 768px) {
    .mobile-top-offset {
      margin-top: 45px;
    }
  }
</style>
