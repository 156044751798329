<template lang="pug">
  .role-actions
    v-card-head-label
      v-editable(
        v-if="loaded"
        :disabled="!isRoleNameEditable"
        @change="updateRoleName")
        h3.title {{ currentRole.name }}
      .name-skeleton(v-else)

    v-card-head-toolbar
      v-btn(
        v-if="showRemoveRoleBtn"
        text
        danger
        small
        paddingless
        v-tooltip="$t('infotext.delete_role_tip')"
        @click="deleteCurrentRole")
        i.fa.fa-trash-alt
        span {{ 'ui.buttons.remove' | translate }}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { MODAL_TYPES } from '@/util/constants'

export default {
  name: 'RoleActions',

  async mounted () {
    if (this.roleId !== undefined) {
      await this.readRole(this.roleId)
      this.loaded = true
    }
  },

  data: () => ({
    loaded: false
  }),

  methods: {
    ...mapActions('modals', [
      'openConfirmModal'
    ]),

    ...mapActions('roles', [
      'readRole',
      'updateRole',
      'deleteRole'
    ]),

    updateRoleName (name) {
      this.currentRole.name = name
      this.updateRole(this.currentRole)
    },

    deleteCurrentRole () {
      const options = {
        content: {
          title: this.$t('ui.modals.delete_message_with_params', { name: this.currentRole.name })
        },
        props: {
          type: MODAL_TYPES.WARNING_RED
        },
        buttons: {
          apply: {
            label: 'ui.buttons.delete',
            callback: this.onRoleDelete
          }
        }
      }
      this.openConfirmModal(options)
    },

    onRoleDelete () {
      this.deleteRole(this.currentRole)
      this.$router.replace(this.roles[0].path)
    }
  },

  computed: {
    ...mapGetters('roles', [
      'roles',
      'currentRole'
    ]),

    roleId () {
      return this.$route.params.role_id
    },

    isRoleNameEditable () {
      return !this.currentRole.isDefault &&
        !this.currentRole.isSuperAdmin && !this.currentRole.isSupervisor
    },

    showRemoveRoleBtn () {
      return this.loaded &&
        !this.currentRole.isDefault &&
        !this.currentRole.isSuperAdmin && !this.currentRole.isSupervisor
    }
  },

  watch: {
    async roleId (value) {
      if (value !== undefined) {
        this.loaded = false
        await this.readRole(value)
        this.loaded = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_mixins.scss';

  .role-actions {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;

    .name-skeleton {
      height: 27px;
      width: 145px;
      border-radius: 5px;
      @include skeleton;
    }
  }
</style>
