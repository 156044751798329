<template lang="pug">
  .role-permissions
    h3.list-title {{ 'ui.labels.permissions' | translate }}
    v-scrollable
      div(style="padding-right: 15px")
        h4.list-subtitle {{ 'pages.administration.page_access' | translate }}
          v-info-icon(:text="$t('infotext.allow_this_role_to_access_specific_pages')")
        ul.list-1
          v-switch(
            v-for="i in 4"
            icon
            block
            outline
            padding
            label-position="left"
            :key="i"
            :loading="currentRoleLoading"
            :disabled="isSuperAdmin"
            :label="$t(items[i - 1].name)"
            :value="items[i - 1].value || isSuperAdmin"
            @input="toggleValue(items[i - 1])")
        h4.list-subtitle {{ 'pages.administration.skilltree_access' | translate }}
        ul.list-1
          v-switch(
            icon
            block
            outline
            padding
            label-position="left"
            :loading="currentRoleLoading"
            :disabled="isSuperAdmin"
            :label="$t(items[4].name)"
            :value="items[4].value || isSuperAdmin"
            @input="toggleValue(items[4])")
            v-info-icon(
              slot="label"
              :text="$t('infotext.show_badges_and_categories_that_are_marked_as_hidden')")
          v-switch(
            icon
            block
            outline
            padding
            label-position="left"
            :loading="currentRoleLoading"
            :disabled="isSuperAdmin"
            :label="$t('permissions.veiw_foreign_profiles')"
            :value="isViewPermissionsActive || isSuperAdmin"
            @input="onViewPermissionChange")
            v-info-icon(
              slot="label"
              :text="$t('infotext.access_the_full_profile_of_another_user_including_all_skills_and_the_profile_description')")
          template(v-if="isViewPermissionsActive")
            ul.list-1
              v-select(
                single
                fixed
                :placeholder="$t('ui.labels.select_an_option')"
                :skeleton="currentRoleLoading"
                :disabled="isSuperAdmin"
                :options="viewPermissionsOptions"
                v-model="viewPermission"
                @update="onSelectChange")

            v-switch(
              icon
              block
              outline
              padding
              label-position="left"
              :loading="currentRoleLoading"
              :disabled="isSuperAdmin"
              :label="$t('permissions.edit_foreign_goals')"
              :value="isEditForeignGoalsPermissionsActive || isSuperAdmin"
              @input="onGoalPermissionChange")
            ul.list-1(v-if="isEditForeignGoalsPermissionsActive")
              v-select(
                single
                fixed
                :placeholder="$t('ui.labels.select_an_option')"
                :skeleton="currentRoleLoading"
                :disabled="isSuperAdmin"
                :options="editForeignGoalsPermissionsOptions"
                v-model="editForeignGoalsPermission"
                @update="onSelectChange")

            v-switch(
              icon
              block
              outline
              padding
              label-position="left"
              :loading="currentRoleLoading"
              :disabled="isSuperAdmin"
              :label="$t('permissions.edit_foreign_profiles')"
              :value="isEditPermissionsActive || isSuperAdmin"
              @input="onEditPermissionChange")
              v-info-icon(
                slot="label"
                :text="$t('infotext.full_access_to_manage_the_profile_of_another_user_including_skills_and_goals')")
            ul.list-1(v-if="isEditPermissionsActive")
              v-select(
                single
                fixed
                hideBottomMargin
                :placeholder="$t('ui.labels.select_an_option')"
                :skeleton="currentRoleLoading"
                :disabled="isSuperAdmin"
                :options="editPermissionsOptions"
                v-model="editPermission"
                @update="onSelectChange")

            v-switch(
              icon
              block
              outline
              padding
              label-position="left"
              :loading="currentRoleLoading"
              :disabled="isSuperAdmin"
              :label="$t(items[5].name)"
              :value="items[5].value || isSuperAdmin"
              @input="toggleValue(items[5])")

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  VIEW_PERMISSIONS_OPTIONS,
  VIEW_FOREIGN_GOALS_PERMISSIONS_OPTIONS,
  EDIT_PERMISSIONS_OPTIONS
} from './options'

const PERMISSIONS = {
  PAGE_SKILL_FINDER: { id: 1, value: false },
  PAGE_SKILLTREE_BUILDER: { id: 2, value: false },
  PAGE_ANALYSIS: { id: 3, value: false },
  PAGE_ADMINISTRATION: { id: 4, value: false },
  SKILLTREE_SHOW_HIDDEN_BRANCHES: { id: 5, value: false },
  SKILLTREE_VIEW_ALL_EMPLOYEES_PROFILES: { id: 6, value: false },
  SKILLTREE_VIEW_OWN_EMPLOYEES_PROFILES: { id: 9, value: false },
  SKILLTREE_EDIT_ALL_EMPLOYEES_PROFILES: { id: 7, value: false },
  SKILLTREE_EDIT_OWN_EMPLOYEES_PROFILES: { id: 8, value: false },
  SKILLTREE_VIEW_SAME_DEPARTMENT_EMPLOYEES_PROFILES: { id: 10, value: false },
  SKILLTREE_EDIT_SAME_DEPARTMENT_EMPLOYEES_PROFILES: { id: 11, value: false },
  SKILLTREE_VIEW_ALL_EMPLOYEES_GOALS: { id: 12, value: false },
  SKILLTREE_VIEW_OWN_EMPLOYEES_GOALS: { id: 13, value: false },
  SKILLTREE_VIEW_SAME_DEPARTMENT_EMPLOYEES_GOALS: { id: 14, value: false },
  SKILLTREE_SHARE_PROFILE: { id: 15, value: false }
}

export default {
  name: 'RolePermissions',

  mounted () {
    if (this.currentRolePermissions) {
      this.updatePermissionsValues()
    }
  },

  data: () => ({
    isViewPermissionsActive: false,
    viewPermissionsOptions: VIEW_PERMISSIONS_OPTIONS,
    viewPermission: null,

    isEditForeignGoalsPermissionsActive: false,
    editForeignGoalsPermissionsOptions: VIEW_FOREIGN_GOALS_PERMISSIONS_OPTIONS,
    editForeignGoalsPermission: null,

    isEditPermissionsActive: false,
    editPermissionsOptions: EDIT_PERMISSIONS_OPTIONS,
    editPermission: null,
    items: [
      {
        name: 'permissions.skill_finder',
        code: 'PAGE_SKILL_FINDER',
        value: false
      },
      {
        name: 'permissions.skilltree_builder',
        code: 'PAGE_SKILLTREE_BUILDER',
        value: false
      },
      {
        name: 'permissions.analysis',
        code: 'PAGE_ANALYSIS',
        value: false
      },
      {
        name: 'permissions.administration',
        code: 'PAGE_ADMINISTRATION',
        value: false
      },
      {
        name: 'permissions.show_hidden_branches',
        code: 'SKILLTREE_SHOW_HIDDEN_BRANCHES',
        value: false
      },
      {
        name: 'permissions.share_foreign_profile',
        code: 'SKILLTREE_SHARE_PROFILE',
        value: false
      }
    ]
  }),

  methods: {
    ...mapActions('permissions', [
      'loadPermission',
      'linkRole',
      'unlinkRole'
    ]),

    toggleValue (item) {
      item.value = !item.value
      const permission = PERMISSIONS[item.code]
      this.changeValue(item.value, permission)
    },

    updatePermissionsValues () {
      // Init switchers
      this.items.forEach(item => {
        item.value = this.currentRole
          ? this.currentRolePermissions[item.code]
          : false
      })

      // Init current permissions
      for (const key in PERMISSIONS) {
        PERMISSIONS[key].value = this.currentRole
          ? this.currentRolePermissions[key]
          : false
      }

      // Init VIEW permissions controls
      if (PERMISSIONS['SKILLTREE_VIEW_OWN_EMPLOYEES_PROFILES'].value ||
        PERMISSIONS['SKILLTREE_VIEW_ALL_EMPLOYEES_PROFILES'].value ||
        PERMISSIONS['SKILLTREE_VIEW_SAME_DEPARTMENT_EMPLOYEES_PROFILES'].value) {
        this.viewPermission = this.findRelevantOption(this.viewPermissionsOptions)
        this.isViewPermissionsActive = true
      } else {
        this.isViewPermissionsActive = false
      }

      // Init VIEW GOALS permissions controls
      if (PERMISSIONS['SKILLTREE_VIEW_OWN_EMPLOYEES_GOALS'].value ||
        PERMISSIONS['SKILLTREE_VIEW_ALL_EMPLOYEES_GOALS'].value ||
        PERMISSIONS['SKILLTREE_VIEW_SAME_DEPARTMENT_EMPLOYEES_GOALS'].value) {
        this.editForeignGoalsPermission = this.findRelevantOption(this.editForeignGoalsPermissionsOptions)
        this.isEditForeignGoalsPermissionsActive = true
      } else {
        this.isEditForeignGoalsPermissionsActive = false
      }

      // Init EDIT permissions controls
      if (PERMISSIONS['SKILLTREE_EDIT_OWN_EMPLOYEES_PROFILES'].value ||
        PERMISSIONS['SKILLTREE_EDIT_ALL_EMPLOYEES_PROFILES'].value ||
        PERMISSIONS['SKILLTREE_EDIT_SAME_DEPARTMENT_EMPLOYEES_PROFILES'].value) {
        this.editPermission = this.findRelevantOption(this.editPermissionsOptions)
        this.isEditPermissionsActive = true
      } else {
        this.isEditPermissionsActive = false
      }
    },

    // return: option for v-select based on current role permissions
    findRelevantOption (options = []) {
      return options.find(item => {
        for (const perm of item.permissions) {
          if (PERMISSIONS[perm.code].value !== perm.value) {
            return false
          }
        }
        return true
      })
    },

    onViewPermissionChange (value) {
      if (value) {
        this.viewPermission = this.viewPermissionsOptions[0]
        this.onSelectChange(this.viewPermission)
        this.isViewPermissionsActive = true
      } else {
        this.isViewPermissionsActive = false
        this.isEditPermissionsActive = false
        this.isEditForeignGoalsPermissionsActive = false
        this.onSelectChange({
          permissions: [
            { code: 'SKILLTREE_VIEW_OWN_EMPLOYEES_PROFILES', value: false },
            { code: 'SKILLTREE_VIEW_SAME_DEPARTMENT_EMPLOYEES_PROFILES', value: false },
            { code: 'SKILLTREE_VIEW_ALL_EMPLOYEES_PROFILES', value: false },
            { code: 'SKILLTREE_EDIT_OWN_EMPLOYEES_PROFILES', value: false },
            { code: 'SKILLTREE_EDIT_SAME_DEPARTMENT_EMPLOYEES_PROFILES', value: false },
            { code: 'SKILLTREE_EDIT_ALL_EMPLOYEES_PROFILES', value: false },
            { code: 'SKILLTREE_VIEW_ALL_EMPLOYEES_GOALS', value: false },
            { code: 'SKILLTREE_VIEW_OWN_EMPLOYEES_GOALS', value: false },
            { code: 'SKILLTREE_VIEW_SAME_DEPARTMENT_EMPLOYEES_GOALS', value: false }
          ]
        })
      }
    },

    onGoalPermissionChange (value) {
      if (value) {
        this.editForeignGoalsPermission = this.editForeignGoalsPermissionsOptions[0]
        this.onSelectChange(this.editForeignGoalsPermission)
        this.isEditForeignGoalsPermissionsActive = true
      } else {
        this.isEditForeignGoalsPermissionsActive = false
        this.onSelectChange({
          permissions: [
            { code: 'SKILLTREE_VIEW_OWN_EMPLOYEES_GOALS', value: false },
            { code: 'SKILLTREE_VIEW_SAME_DEPARTMENT_EMPLOYEES_GOALS', value: false },
            { code: 'SKILLTREE_VIEW_ALL_EMPLOYEES_GOALS', value: false }
          ]
        })
      }
    },

    onEditPermissionChange (value) {
      if (value) {
        this.editPermission = this.editPermissionsOptions[0]
        this.onSelectChange(this.editPermission)
        this.isEditPermissionsActive = true
      } else {
        this.isEditPermissionsActive = false
        this.onSelectChange({
          permissions: [
            { code: 'SKILLTREE_EDIT_OWN_EMPLOYEES_PROFILES', value: false },
            { code: 'SKILLTREE_EDIT_SAME_DEPARTMENT_EMPLOYEES_PROFILES', value: false },
            { code: 'SKILLTREE_EDIT_ALL_EMPLOYEES_PROFILES', value: false }
          ]
        })
      }
    },

    onSelectChange (item) {
      for (const el of item.permissions) {
        const permission = PERMISSIONS[el.code]
        if (el.value !== permission.value) {
          this.changeValue(el.value, permission)
        }
      }
    },

    changeValue (value, permission) {
      const payload = {
        id: permission.id,
        roleId: this.$route.params.role_id
      }
      permission.value = value
      if (value) {
        this.linkRole(payload)
      } else {
        this.unlinkRole(payload)
      }
    }
  },

  computed: {
    ...mapGetters('roles', [
      'currentRole',
      'currentRoleLoading'
    ]),

    isSuperAdmin () {
      return this.currentRole &&
        !!this.currentRole.isSuperAdmin
    },

    isDefault () {
      return this.currentRole &&
        !!this.currentRole.isDefault
    },

    currentRolePermissions () {
      return this.currentRole &&
        this.currentRole.permissions
    }
  },

  watch: {
    currentRolePermissions () {
      this.updatePermissionsValues()
    }
  }
}
</script>

<style lang="scss" scoped>
  .role-permissions {
    &__label {
      font-size: 18px;
      color: $h2-title;
      height: 38px;
      width: 100%;
      display: flex;
      align-items: center;
      padding-bottom: 1rem;
    }
  }
</style>
