export const VIEW_PERMISSIONS_OPTIONS = [
  {
    id: 0,
    name: 'permissions.subordinated_employees',
    permissions: [
      { code: 'SKILLTREE_VIEW_OWN_EMPLOYEES_PROFILES', value: true },
      { code: 'SKILLTREE_VIEW_SAME_DEPARTMENT_EMPLOYEES_PROFILES', value: false },
      { code: 'SKILLTREE_VIEW_ALL_EMPLOYEES_PROFILES', value: false }
    ]
  },
  {
    id: 1,
    name: 'permissions.my_department',
    permissions: [
      { code: 'SKILLTREE_VIEW_OWN_EMPLOYEES_PROFILES', value: false },
      { code: 'SKILLTREE_VIEW_SAME_DEPARTMENT_EMPLOYEES_PROFILES', value: true },
      { code: 'SKILLTREE_VIEW_ALL_EMPLOYEES_PROFILES', value: false }
    ]
  },
  {
    id: 2,
    name: 'permissions.all_employees',
    permissions: [
      { code: 'SKILLTREE_VIEW_OWN_EMPLOYEES_PROFILES', value: false },
      { code: 'SKILLTREE_VIEW_SAME_DEPARTMENT_EMPLOYEES_PROFILES', value: false },
      { code: 'SKILLTREE_VIEW_ALL_EMPLOYEES_PROFILES', value: true }
    ]
  }
]

export const VIEW_FOREIGN_GOALS_PERMISSIONS_OPTIONS = [
  {
    id: 0,
    name: 'permissions.subordinated_employees',
    permissions: [
      { code: 'SKILLTREE_VIEW_OWN_EMPLOYEES_GOALS', value: true },
      { code: 'SKILLTREE_VIEW_SAME_DEPARTMENT_EMPLOYEES_GOALS', value: false },
      { code: 'SKILLTREE_VIEW_ALL_EMPLOYEES_GOALS', value: false }
    ]
  },
  {
    id: 1,
    name: 'permissions.my_department',
    permissions: [
      { code: 'SKILLTREE_VIEW_OWN_EMPLOYEES_GOALS', value: false },
      { code: 'SKILLTREE_VIEW_SAME_DEPARTMENT_EMPLOYEES_GOALS', value: true },
      { code: 'SKILLTREE_VIEW_ALL_EMPLOYEES_GOALS', value: false }
    ]
  },
  {
    id: 2,
    name: 'permissions.all_employees',
    permissions: [
      { code: 'SKILLTREE_VIEW_OWN_EMPLOYEES_GOALS', value: false },
      { code: 'SKILLTREE_VIEW_SAME_DEPARTMENT_EMPLOYEES_GOALS', value: false },
      { code: 'SKILLTREE_VIEW_ALL_EMPLOYEES_GOALS', value: true }
    ]
  }
]

export const EDIT_PERMISSIONS_OPTIONS = [
  {
    id: 0,
    name: 'permissions.subordinated_employees',
    permissions: [
      { code: 'SKILLTREE_EDIT_OWN_EMPLOYEES_PROFILES', value: true },
      { code: 'SKILLTREE_EDIT_SAME_DEPARTMENT_EMPLOYEES_PROFILES', value: false },
      { code: 'SKILLTREE_EDIT_ALL_EMPLOYEES_PROFILES', value: false }
    ]
  },
  {
    id: 1,
    name: 'permissions.my_department',
    permissions: [
      { code: 'SKILLTREE_EDIT_OWN_EMPLOYEES_PROFILES', value: false },
      { code: 'SKILLTREE_EDIT_SAME_DEPARTMENT_EMPLOYEES_PROFILES', value: true },
      { code: 'SKILLTREE_EDIT_ALL_EMPLOYEES_PROFILES', value: false }
    ]
  },
  {
    id: 2,
    name: 'permissions.all_employees',
    permissions: [
      { code: 'SKILLTREE_EDIT_OWN_EMPLOYEES_PROFILES', value: false },
      { code: 'SKILLTREE_EDIT_SAME_DEPARTMENT_EMPLOYEES_PROFILES', value: false },
      { code: 'SKILLTREE_EDIT_ALL_EMPLOYEES_PROFILES', value: true }
    ]
  }
]
